.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    /* transform: rotate(0deg); */
    background-position: 0vw;
  }
  to {
    /* transform: rotate(360deg); */
    background-position: 100vw;
  }
}

header p {
  animation: App-logo-spin infinite 20s linear;
  background: repeating-linear-gradient(
    44.4deg,
    hsl(0deg 100% 50%) calc(calc(calc(100vw / 6) * 0) / 10),
    hsl(0deg 100% 27%) calc(calc(calc(100vw / 6) * 0.1) / 10),
    hsl(0deg 100% 50%) calc(calc(calc(100vw / 6) * 2.9) / 10),
    hsl(60deg 100% 50%) calc(calc(calc(100vw / 6) * 3) / 10),
    hsl(60deg 100% 27%) calc(calc(calc(100vw / 6) * 3.1) / 10),
    hsl(60deg 100% 50%) calc(calc(calc(100vw / 6) * 5.9) / 10),
    hsl(0deg 100% 50%) calc(calc(calc(100vw / 6) * 6) / 10)
  );
  width: -webkit-fill-available;
  padding: 35px;
  text-shadow: 0px 2px 6px black;
}
